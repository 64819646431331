.minimized-sidebar-container {
  position: fixed;
  top: 0;
  left: 18px;
  width: 48px;
  height: 100vh;
  padding: 1.25rem 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  hr {
    margin: 0; // Required because it is inside of a flex container, so margin: auto needs to be reset.
    border-top: 1px solid #e9eaea;
    width: 48px;
  }

  .hamburger-collapse-button {
    margin: 0;
    padding: 0.5rem 0.75rem !important;
    min-width: auto;
  }

  .icon-group {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.25rem;
  }

  .logout-button-minimized {
    padding: 0.5rem 0.75rem !important;
    min-width: auto;
    margin: 0;
  }
}

@import '../../assets/sass/theme';

.trip-request-wallet-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 670px;
  max-height: calc(100vh - 32px - 32px); // 32px gutter on top and bottom

  @media screen and (max-width: 1112px) {
    height: calc(100vh - 48px); // 48px for top nav
    max-height: initial;
  }

  .header-container {
    display: flex;
    justify-content: space-between;
    padding: 1rem 0.75rem 1rem 1.25rem;
    border-bottom: 1px solid $gray-300;

    button {
      padding: 0.25rem 0.5rem;
      min-width: auto;
    }

    .header {
      font-size: 18px;
      font-weight: 600;
      color: $gray-700;
    }
  }

  .body-content {
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden; // Needed to hide strange x-axis scrollbar bug when opening/closing accordion.
  }

  .additional-payment-methods {
    padding: 20px;
  }

  button {
    text-transform: inherit;
  }

  &.loading {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .close-button {
    min-width: auto !important;
    padding: 4px 8px !important;
    margin: 0;
  }
}

@import '../../assets/sass/theme';

.roundedButton {
  outline: none;
  border-radius: 40px;
}

// TODO: Remove this styling, use the overrides in theme.js, and then confirm all buttons on the site still look good.
.MuiButtonBase-root.MuiButton-root {
  border-color: $blaise-purple;
  text-transform: none;
  padding: 4px 16px;
  outline: none;
  font-size: 12pt;
}

.MuiButtonBase-root.MuiButton-root.MuiButtonGroup-grouped.MuiButtonGroup-groupedOutlined.roundedButton.MuiButton-outlined.MuiButton-sizeSmall {
  border-radius: 50px 0 0 50px;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
    0px 2px 1px -1px rgba(0, 0, 0, 0.12);
  outline-color: $blaise-purple;
}

.MuiButtonBase-root.MuiButton-root.MuiButtonGroup-grouped.MuiButtonGroup-groupedOutlined.roundedButton2.MuiButton-outlined.MuiButton-sizeSmall {
  border-radius: 0 50px 50px 0;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
    0px 2px 1px -1px rgba(0, 0, 0, 0.12);
  outline-color: $blaise-purple;
}

.MuiButton-outlined {
  border-color: $blaise-purple;
}

.download-the-app-popup-container {
  position: absolute;
  bottom: 10px;
  padding: 20px;
  border: 2px solid $blaise-green;
  border-radius: 5px;
  background-color: $white;

  @media screen and (max-width: 1112px) {
    margin: 0 10px;
  }

  .close-popup {
    position: absolute;
    right: -6px;
    top: 0;
  }
}

@import '../../assets/sass/theme';

#tripAsset {
  margin: 0px 5px 0 0;
  height: 160px;
  position: static;
}

.location {
  min-width: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 40vw;
}

.locationPending {
  min-width: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 40vw;
  color: $blaise-gray;
}

.time {
  text-align: right;
  width: 60px;
  white-space: nowrap;
  color: $blaise-gray;
}

.loading {
  animation: pulse 1s alternate infinite;
  -webkit-animation: pulse 1s alternate infinite;
  color: $blaise-gray;
}

@keyframes pulse {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@-webkit-keyframes pulse {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@import '../../assets/sass/theme';

.sidebarWrapper {
  height: 100vh;
  width: 300px;
  position: relative;
  flex-grow: 0;
  flex-shrink: 0;
  z-index: 99;
  background-color: #fff;
  transition: margin-left 250ms ease-out;
  box-shadow: 1px 2px 2px $gray-300;
  padding: 1.25rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: auto;

  .MuiPaper-elevation1 {
    box-shadow: none;
  }

  // Overwrite default accordion styling
  .MuiAccordion-root.Mui-expanded {
    margin: 0;
  }

  .MuiAccordionSummary-content.Mui-expanded {
    margin: 12px 0;
  }

  .MuiAccordionSummary-root {
    min-height: 40px;
    height: 40px;
  }

  .MuiAccordionSummary-root.Mui-expanded {
    min-height: 40px;
  }

  .MuiAccordion-root:before {
    display: none;
  }

  .accordion-title {
    padding-right: 8px !important;

    &:hover {
      background-color: #f3f4f6;
      border-radius: 4px;
    }
  }
}

.sidebarWrapper.collapsed {
  margin-left: -212px; // 88px wide collapsed sidebar.
}

.sidebarTop {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.25rem;
}

.logoWrapper {
  display: flex;
  align-items: center;
  gap: 0.25rem;
}

.companyName {
  color: $gray-700;
  font-weight: bold;
  font-size: 1.25rem;
}

.logo {
  height: 50px;

  @media screen and (max-width: 1112px) {
    height: 30px;
  }
}

.collapseBtn {
  min-width: auto !important;
  padding: 4px 8px !important;
  margin: 0;
}

.linksGrid {
  display: grid;
  gap: 0.5rem;
}

.logoutBtn {
  width: 100%;
  background-color: transparent;
  padding: 0.5rem;
  border-radius: 4px;
  color: $gray-600;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1rem;
  font-weight: 500;

  &:hover {
    background-color: #fef2f2;
    color: #eb5757;
  }
}

.otherLinks a {
  color: $gray-700;
  margin-left: 2rem;
}

.appVersion {
  color: $gray-500;
  margin-left: 0.5rem;
  margin-top: 0.5rem;
}

.download-the-app-wrapper {
  display: flex;
  align-items: center;
  border-radius: 4px;

  &:hover {
    background-color: $blaise-light-green;
    cursor: pointer;
  }

  .app-icon-wrapper {
    height: 45px;
    width: 45px;
    border: 2px solid $gray-300;
    border-radius: 5px;
    padding: 6px;
    display: grid;
    place-items: center;
  }

  .download-the-app-text {
    color: $gray-600;
    font-size: 1rem;
    font-weight: 600;
    margin-left: 10px;
  }
}

/* ANIMATION CSS */
.transition-fade-enter {
  opacity: 0;
}

.transition-fade-enter-active {
  opacity: 1;
  transition: opacity 500ms ease; // Animation duration needs to match MountAnimation's timeout.
}

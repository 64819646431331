@import '../../../assets/sass/theme';

.where-and-when-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 670px;
  max-height: calc(100vh - 32px - 32px); // 32px gutter on top and bottom

  @media screen and (max-width: 1112px) {
    height: fit-content;
  }

  .dot-container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 40px;

    svg {
      align-self: center;
    }

    hr {
      margin: 0; // Required because it is inside of a flex container, so margin: auto needs to be reset.
      transform: rotate(90deg);
      border-top: 2px dashed black;
    }
  }

  .origin-destination-container {
    position: relative;
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    .walking-distance {
      position: absolute;
      left: 10px;
      font-size: 14px !important; // Overwrite Typography font-size.
      display: flex;
      align-items: center;

      svg {
        margin-right: 6px;
      }
    }
  }

  .switch-input-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;

    button {
      padding: 0.5rem 0.75rem;
      min-width: auto;
    }
  }

  .travel-preference-dropdown {
    width: 120px;
    height: 40px;

    > div[role='button'] {
      // Needed to fix MUI on hover effect for 40px height input.
      padding-top: 10.5px;
      padding-bottom: 10.5px;
    }
  }

  .date-field {
    height: 40px;
    background-color: $blaise-light-green;
    text-decoration: none;
    padding: 4px 10px;
    border-radius: 4px;
    flex-grow: 1;

    ::after,
    ::before {
      display: none;
    }

    button {
      margin-right: -9px; // Needed to fix on hover effect of calendar icon.
      padding: 6px;
    }
  }

  .origin-destination-mobile-button {
    padding: 0;
    text-transform: capitalize;
  }
}

.next-button {
  padding: 20px;
  border-top: 2px solid $gray-300;

  button {
    width: 100%;
    text-transform: initial;
    font-weight: 700;
  }
}

.address-search-result-container {
  overflow: hidden auto;
  height: 100%;
}

@import '../../../assets/sass/theme';

.modal-container {
  height: 100vh;
  background-color: $white;

  .close-button-container {
    width: 100%;
    height: 48px;
    display: flex;
    justify-content: flex-end;
    padding: 8px 20px;

    .close-button {
      margin: 0;
      padding: 0.5rem 0 0.5rem 0.5rem !important;
      min-width: auto;
    }
  }

  .clear-input {
    margin: 0;
    padding: 0.5rem !important;
    min-width: auto;
  }
}

@import '../../../assets/sass/theme';

.add-passenger-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 670px;
  max-height: calc(100vh - 32px - 32px); // 32px gutter on top and bottom

  @media screen and (max-width: 1112px) {
    height: calc(100vh - 48px); // 48px for top nav
    max-height: initial;
  }

  .content {
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden; // Needed to hide strange x-axis scrollbar bug when opening/closing accordion.
  }

  button {
    text-transform: initial;
    font-weight: 600;
  }

  .header-container {
    display: flex;
    justify-content: space-between;
    padding: 1rem 0.75rem 1rem 1.25rem;
    border-bottom: 1px solid $gray-300;

    button {
      padding: 0.25rem 0.5rem;
      min-width: auto;
    }

    .header {
      font-size: 18px;
      font-weight: 600;
      color: $gray-700;
      text-transform: capitalize;
    }
  }

  .passengers-container {
    // Overwrite default accordion styling
    .accordion {
      box-shadow: none;
      margin: 0 !important;
      border-top: 1px solid $gray-300;

      &.expanded {
        margin: 0 !important;
      }
    }

    .accordion-title {
      min-height: 40px !important;
      height: 40px !important;
      padding: 0 20px !important;
      border-bottom: 1px solid $gray-300;

      &:hover {
        background-color: #f3f4f6;
        border-radius: 4px 4px 0px 0px;
      }
    }

    .accordion-content {
      padding: 20px;
      display: grid;
      gap: 1rem;
    }

    .section-title-container {
      margin-bottom: 0.5rem;

      .section-title {
        font-size: 1rem;
        font-weight: 600;
        color: $gray-700;
      }

      .section-subtitle {
        font-size: 0.75rem;
      }
    }

    .passenger-type-checkbox {
      :first-child {
        fill: #8176ba !important;
      }
    }

    .accommodation {
      width: 100%;
      background-color: $white;
      border: 1px solid $gray-200;
      border-radius: 4px;
      font-weight: 500;

      &.selected {
        background-color: #edebf5;
        border-color: #544890;
      }
    }
  }

  .add-passenger-button-container {
    margin-bottom: 44px; // So that the button will always be above the save button
    border-top: 1px solid $gray-300;

    .add-passenger-text {
      color: $gray-700;
      font-weight: 500;
    }

    button {
      width: 100%;
      padding: 1rem 1.25rem;
      display: flex;
      justify-content: flex-start;
    }
  }

  .remove-passenger-button {
    background-color: #fee2e2;
    border: 1px solid #fecaca;
    margin-top: 1rem;
  }

  .save-button {
    position: absolute;
    bottom: 20px;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;

    button {
      width: 380px;
    }
  }
}

@import '../assets/sass/theme';

.mobile-sidebar-drawer {
  .MuiDrawer-paperAnchorRight {
    width: 100%;

    .sidebarWrapper {
      width: 100%;
      padding-top: 8px;
    }
  }
}

.mobile-topnav {
  background-color: white;
  width: 100%;
  height: 48px;
  display: flex;
  justify-content: space-between;
  padding: 8px 20px;
  z-index: 1;

  .hamburger-collapse-button {
    margin: 0;
    padding: 0.5rem 0 0.5rem 0.5rem !important;
    min-width: auto;
  }
}

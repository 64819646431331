#planTripBtn {
  position: absolute;
  bottom: 20px;
  z-index: 1;
}

.map {
  z-index: 0;
  height: 100vh;
  position: relative;
}

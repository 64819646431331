@import '../../assets/sass/theme';

li {
  padding: 8px 10px;
  font-family: 'Source Sans Pro', sans-serif;

  &:hover {
    color: black;
    background-color: $white;
  }
}

.qr-code-instructions-modal {
  padding: 32px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 720px;

  .close-popup {
    position: absolute;
    right: -6px;
    top: 0;
  }
}

.paper {
  position: absolute;
  background-color: white;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 5px;
  padding: 30px;

  @media screen and (max-width: 1112px) {
    width: 90%;
  }
}

.ticket-modal-title {
  font-size: 20px;
}

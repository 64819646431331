@import '../src/assets/sass/theme';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fff;

  @media screen and (max-width: 1112px) {
    overflow: hidden; // Needed so that there is no scrollbar on mobile, when using HomeMap's mobile window.innerHeight trick.
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

button {
  outline: none;
  border: none;

  :hover {
    cursor: pointer;
  }
}

body.Ride.custom-background {
  background-image: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.blaiseblue {
  background-color: $blaise-green;
}

.blaise-turquoise-white-font {
  color: #fff;
  background-color: $blaise-green;
}

.blaisepurple {
  color: #fff;
  background-color: $blaise-purple;
  border-color: $blaise-purple;
}

.blaisegray {
  color: #fff;
  background-color: $blaise-gray;
  border-color: $blaise-gray;
}

.blaiselightgray {
  background-color: $blaise-light-gray;
}

.blaisered {
  background-color: $blaise-red;
  color: #fff;
}

.blaisepurple {
  background-color: $blaise-purple;
  color: #fff;
}

.centered {
  position: absolute;
  left: 50%;
  width: 90vw;
  margin-left: -(90vw/2);
}

.bordered {
  border: 1px solid black;
}

.whitebackground {
  background-color: white;
}

.blaise-progress {
  margin-top: 300px;
  text-align: center;
}

.blaise-progress-top {
  margin-top: 20px;
  text-align: center;
}

.MuiCircularProgress-colorPrimary {
  color: $blaise-green !important;
}

.roundedButton {
  border-radius: 20px;
  padding: 5px 20px;
  border: none;
  outline: none;
  font-size: 12pt;
  font-weight: bold;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
    0px 2px 1px -1px rgba(0, 0, 0, 0.12);

  // the following attributes prevent the button text from being selectable
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.roundedButton:hover {
  filter: brightness(90%);
}

.roundedButton:active,
.roundedButton:focus {
  outline: 0;
  border: none;
  -moz-outline-style: none;
}

@import '../../assets/sass/theme';

.profile-picture {
  margin-bottom: 20px;
  margin-top: 20px;
  width: 150px !important;
  height: 150px !important;
}

legend {
  text-align: center;
}

.MuiFormControl-root.MuiTextField-root {
  max-width: 350px;
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: $blaise-green !important;
}

.form-group label {
  text-align: start;
}

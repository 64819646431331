$blaise-green: #00cdad;
$blaise-light-green: #f2faf8;
$blaise-gray: #7a7a7a;
$blaise-light-gray: #e6e6e6;
$blaise-purple: #52479c;
$white: #ffffff;
$blaise-red: #f94346;

$gray-100: #f3f4f6;
$gray-200: #e5e7eb;
$gray-300: #d1d5db;
$gray-400: #9ca3af;
$gray-500: #6b7280;
$gray-600: #4b5563;
$gray-700: #374151;
$gray-800: #1f2937;

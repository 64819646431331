@import '../../assets/sass/_theme';

.container {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;

  .logo {
    color: $blaise-green;
  }
}

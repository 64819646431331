@import '../../../assets/sass/theme';

.address-container {
  padding: 12px 20px !important;
  width: 100%;
  border-top: 1px solid $gray-300 !important;
  border-radius: 0 !important;
  justify-content: flex-start !important;

  svg {
    margin-right: 1rem;
  }

  .address-text {
    width: 350px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
    text-transform: none;
  }

  .address-secondary {
    width: 350px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
    text-transform: none;
    color: $blaise-gray;
    font-size: 14px;
  }
}

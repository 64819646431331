.expanded-sidebar-container {
  hr {
    margin: 0; // Required because it is inside of a flex container, so margin: auto needs to be reset.
    border-top: 1px solid #e9eaea;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .hide {
    opacity: 0;
    pointer-events: none;
  }
}

@import '../../../assets/sass/theme';

.passenger-and-payment-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 670px;
  max-height: calc(100vh - 32px - 32px); // 32px gutter on top and bottom

  @media screen and (max-width: 1112px) {
    height: fit-content;
  }

  button {
    text-transform: none;
    font-weight: 600;
  }

  .mobile-scroll-container {
    @media screen and (max-width: 1112px) {
      height: 240px;
      overflow: scroll;
    }
  }

  .top-container {
    padding: 20px;
    border-bottom: 1px solid $gray-300;
  }

  .passengers-payment-button {
    width: 100%;
    display: flex;
    justify-content: space-between;
    border: 1px solid $gray-300;
    margin-bottom: 1rem;
  }

  .note-to-driver-input {
    fieldset {
      border-color: $gray-300;
    }
  }

  .summary-container {
    padding: 20px;
    overflow-y: auto;
    height: 100%;

    hr {
      border-top: 1px solid #e9eaea;
      margin: 0.5rem 0;
    }

    .summary-header {
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 1.5rem;
    }

    .passengers-container {
      display: flex;
      flex-direction: column;
      gap: 4px;
      margin-bottom: 1rem;
    }

    .summary-item {
      display: flex;
      justify-content: space-between;
    }

    .total {
      font-weight: 600;
    }

    .no-show-penalty {
      font-size: 13px;
      margin-top: 1.5rem;
    }
  }

  .request-button {
    position: absolute;
    bottom: 20px;
    width: 100%;
    left: 0;
    display: flex;
    justify-content: center;

    button {
      width: 380px;
    }
  }
}

@import '../../assets/sass/theme';

.item {
  background-color: transparent;
  padding: 0.5rem 0.75rem;
  border-radius: 4px;
  color: $gray-600;
  display: flex;
  align-items: center;
  font-size: 1rem;
  font-weight: 500;

  &:hover {
    background-color: $gray-100;
  }
}

.item.active {
  background-color: $blaise-light-green;
  font-weight: 600;
}

.itemIcon {
  margin-right: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}

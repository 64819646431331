.ratingContainer {
  text-align: center;
  margin-top: 120px;
}

.btn:focus,
.btn:active {
  outline: none;
  box-shadow: none;
}

.ratingPrompt {
  border-radius: 10px;
  font-size: 12pt;
}

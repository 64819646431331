.tripContainer {
  padding: 1rem 0;
  margin: 1rem;
  border-radius: 10px;
  z-index: 4;
  box-shadow: 3px 3px 3px 3px #ccc;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

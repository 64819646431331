@import '../../assets/sass/theme';

.form-group {
  margin-top: 10px;
  margin-bottom: 40px;
  position: relative;
  height: 40px;
}

.walk-text {
  font-size: 12px;
}

.left-button {
  border-radius: 0px 15px 15px 0px;
}

.right-button {
  border-radius: 15px 0px 0px 15px;
}

fieldset {
  text-align: center;
}

#tripIcon {
  margin: 36px 8px 0 0;
  width: 36px;
}

li {
  text-align: left;
  line-height: 1.3;
  padding: 10px 10px;
}

li:hover {
  background-color: $blaise-light-gray;
}

li:active {
  background-color: $blaise-gray;
  color: $white;
}

.centerAlign {
  text-align: center;
}

.datetimeLabel {
  text-align: left;
}

textarea:focus,
input:focus {
  outline: none;
}

// width values for the navigations button at top of calendar
.react-calendar__navigation button {
  min-width: 0;
}

// padding for calendar tiles
.react-calendar__tile {
  padding: 0;
}

// margins for calendar title
.react-calendar__navigation {
  margin: 0;
}

// removes padding for monthly/yearly/decade view
.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2px 0;
}

// removes yearly calendar navigation arrows
.react-calendar__navigation__arrow.react-calendar__navigation__next2-button {
  display: none;
}
.react-calendar__navigation__arrow.react-calendar__navigation__prev2-button {
  display: none;
}

// calendar styling
.calendar {
  width: 220px;
}

// calendar weekend days styling
.react-calendar__month-view__days__day--weekend {
  color: $blaise-green;
}
.react-calendar__month-view__weekdays__weekday {
  padding: 0;
}

.btn-group {
  border: none;
  padding: 10px 20px;
  text-align: center;
  margin: 4px 2px;
  border-radius: 100%;
}

.ta {
  position: absolute;
  padding-bottom: 50px;
}

.roundedButton {
  outline: none;
  border-radius: 40px;
  margin-bottom: 0;
}

.MuiButton-outlined {
  border-color: $blaise-green;
}

.ViewDesktop {
  // For small laptop screen
  @media (max-width: 1280px) {
    margin-top: 20%;
  }
}

.ViewScroll {
  @media (max-width: 1280px) {
    height: 400px;
    overflow-y: scroll;
  }
}

.submitActive {
  opacity: 1;
  pointer-events: auto;
}

.submitInactive {
  opacity: 0.4;
  pointer-events: none;
}

.trip-request-container {
  position: absolute;
  bottom: 32px;
  left: 32px;
  width: 420px;
  overflow: hidden;
  border-radius: 8px 8px 0 0 !important; // Overwrites Paper's radius
  background-color: $white;

  @media (max-width: 1112px) {
    position: relative;
    left: 0;
    bottom: 0;
    width: 100%;
  }
}

.loading-transit-agency-info {
  @media (max-width: 1112px) {
    height: 318.5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
  }
}
